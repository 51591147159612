import React from "react";
import Navbar from "../Navbar/navbar";
import "./privacy.css";
import Footer from "../footer/footer";

function terms() {
  return (
    <div>
      <Navbar />
      <h1 className="privacy-heading">Privacy Policy</h1>
      <p className="text-center privacy-content">
        Whet Cloud LLC, has created this privacy statement in order to
        demonstrate our company commitment to privacy. Whet Cloud LLC, is
        committed to maintaining the confidentiality, integrity, and security of
        any Personal Information (as defined below) about our subscribers to the
        Site. The Company strives to support individuals and business owners
        with the Whet Cloud LLC while respecting your privacy expectations and
        protecting your Personal Information. To demonstrate our commitment to
        protecting your privacy, we have developed this privacy policy (“Privacy
        Policy”), which describes how we will collect, use, disclose and protect
        your Personal Information through the Site.
        <br />
        <br />
        By accessing and/or using the www.whetcloud.com, you agree to all the
        terms and conditions of this Privacy Policy and the Terms of Use found
        here: Term of Use and which are incorporated here by reference. If you
        do not agree to all the terms and conditions of this Privacy Policy and
        the Terms of Use, do not use the www.whetcloud.com
        <br />
        <br />
        The following discloses our information gathering and dissemination
        practices for this website: whetcloud.com
        <br />
        <br />
        Data Collection of Personal Information from Clients or Visitors. Whet
        Cloud LLC, may collect and/or track (1) the home server domain names,
        email addresses, type of client computer, files downloaded, search
        engine used, operating system, and type of web browser of visitors to
        Whet Cloud LLC, web service, (2) the email addresses of visitors that
        communicate with Whet Cloud LLC, via email, (3) information knowingly
        provided by the visitor in online forms, registration forms, surveys,
        email, contest entries, and other online avenues (including demographic
        and personal profile data), and (4) aggregate and user-specific
        information on which pages visitors access.
        <br />
        <br />
        Whet Cloud LLC, may place Internet “cookies” on visitors’ hard drives.
        Internet cookies save data about individual visitors, such as the
        organization’s name, password, user-name, screen preferences, and the
        pages of a service viewed by the visitor. When the visitor revisits Whet
        Cloud LLC, web service, Whet Cloud LLC, may recognize the visitor by the
        Internet cookie and customize the visitor’s experience accordingly.
        Visitors may decline Internet cookies, if any, by using the appropriate
        feature of their web client software, if available.
        <br />
        <br />
        Use of Personal Data Collected.
        <br />
        Personal data collected by Whet Cloud LLC may be used by Whet Cloud LLC,
        for editorial and feedback purposes, for marketing and promotional
        purposes, for a statistical analysis of users’ behavior, for product
        development, for content improvement, or to customize the content and
        layout of Whet Cloud LLC, service. Aggregate data on visitors’ home
        servers may be used for internal purposes but will not be provided to
        third parties such as marketing firms. Individually identifying
        information, such as names, postal and email addresses, phone numbers,
        and other personal information which visitors voluntarily provide to
        Whet Cloud LLC may be added to Whet Cloud LLC, databases and used for
        future calls and mailings regarding service updates, new products and
        services, and upcoming events.
        <br />
        <br />
        Security Measures.
        <br />
        Whet Cloud LLC, has implemented numerous security features to prevent
        the unauthorized release of or access to personal information. Whet
        Cloud LLC, will take appropriate action against any employee who fails
        to acknowledge these facts or adhere to the requisite standards of
        conduct. Please be advised, that Whet Cloud LLC, is not responsible for
        the security of information transmitted via the Internet (electronic
        mail services). For more private communication contact Whet Cloud LLC,
        by telephone.
        <br />
        <br />
        Whet Cloud LLC Right to Contact Users.
        <br />
        Whet Cloud LLC, reserves the right to contact service visitors regarding
        sales and other informational requests made through its web service.
        <br />
        Whet Cloud LLC Right to Change Privacy Policy.
        <br />
        Whet Cloud LLC, reserves the right to change this Privacy Policy at any
        time without notice. Any change to this Privacy Policy shall be
        effective as to any visitor that has accepted the Whet Cloud Service
        Terms and Conditions before the change was made.
        <br />
        <br />
        Contact Information
        <br />
        Whet Cloud LLC, welcomes your comments regarding this Statement of
        Privacy, please contact us by e-mail or postal Whet Cloud LLC.
        <br />
        13391 Torrington Drive
        <br />
        Frisco, Texas 75035
        <br />
        <br />
      </p>
      <Footer />
    </div>
  );
}

export default terms;
