import React from "react";
import "./projects.css";

function projects() {
  return (
    <div>
      <h2 className="container my-5 about-small-heading">About Us</h2>
      <h1 className="container about-main-heading">
        Making Customers Digital Transformation <br /> Strategy a Reality One
      </h1>
    </div>
  );
}

export default projects;
