import React from "react";
import "./company-overview.css";

function companyOverview() {
  return (
    <div>
      <h2 className="container small-heading my-3">Company Overview</h2>
      <p className="content my-3 container">
        We are a process driven technology company with several years of
        experience in application development, we at Whetcloud strive to exceed
        customer expectations by developing innovative and cost effective
        enterprise solutions for our customers.
        <br />
        <br />
        With expertise in process automation we deliver solutions that not only
        day to day operations but also help increase profitability, we are a
        proud Texan based firm specializing in Consulting, digital design,
        digital marketing, Web application design and development as well as
        develop customized software solutions as per as the customer needs.
        <br />
        <br />
        Our development philosophy focuses to provide state of the art solutions
        that support our clients near and long-term business needs.
      </p>
    </div>
  );
}

export default companyOverview;
