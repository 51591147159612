import React from 'react';
import Navbar from '../Navbar/navbar';
import './terms.css';
import Footer from '../footer/footer';

function terms() {
  return (
    <div>
      <Navbar />
      <h1 className='terms-heading'>Terms Of Services</h1>
      <p className='text-center terms-content'>The following terms and conditions (“Terms and Conditions”) govern your use of this web service (www.whetcloud.com) or any other web service or site owned and/or operated by Whet Cloud LLC, (the “Service”), and by accessing, viewing, or using the material and functionality on the Service, you indicate that you understand and intend these Terms and Conditions to be the legal equivalent of a signed, written contract, and equally binding and that you accept such Terms and Conditions and agree to be legally bound by them. If you do not agree with the Terms and Conditions, you are not granted permission to use the Service and should exit immediately.
        <br /><br />
        Proprietary Rights.
        <br />
        All material contained in this Service is protected by law, including, but not limited to, United States copyright law. Except as indicated, the entire content (including images, text, and look and feel attributes) of www.whetcloud.com. All rights reserved. Removing or altering the copyright notice on any material on the Service is prohibited. Whet Cloud LLC, also owns a copyright in this Service as a collective work and/or compilation, and in the selection, coordination, arrangement, organization, and enhancement of such content. Any commercial use of this content is prohibited without the prior written consent of Whet Cloud LLC. All trademarks used at this website that are not the intellectual property of Whet Cloud LLC, are the property of their respective holders.
        <br /><br />
        Communications With Our Web Service.
        <br />
        Whet Cloud LLC, welcomes your feedback and suggestions about how to improve our products and services and this Service. By transmitting any suggestions, information, material, or other content (collectively, “content”) to Whet Cloud LLC., you automatically grant Whet Cloud LLC, the royalty-free, perpetual, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, redistribute, transmit, perform, and display such content (in whole or part) worldwide and/or to incorporate it in other works in any form, media, or technology now known or later developed for the full term of any rights that may exist in such content. Further, Whet Cloud LLC, is free to use any ideas, concepts, know-how, techniques, and suggestions contained in any communications you send to this Service for any purpose whatever, including but not limited to creating and marketing products and/or services using such information.
        <br /><br />
        No Solicitation or Offer or Advice.
        <br />
        This Service is designed to provide general information about Whet Cloud LLC, and its products and services. Information on the Service is not intended to constitute an offer to sell or a solicitation of any particular product or service.
        <br /><br />
        Privacy Statement.
        <br />
        Whet Cloud LLC, has a Privacy Statement disclosing what information we collect about visitors, how we use such information and the steps we take to secure such information. Click here to view the Privacy Statement, which is incorporated by reference, as if set forth fully herein.
        <br /><br />
        Disclaimer of Warranty and Liability.
        <br />
        Use of this service is entirely at your own risk. Neither Whet Cloud LLC, nor its affiliates are responsible for the consequences of reliance on any information contained in or submitted to the service, and the risk of injury from the foregoing rests entirely with you. These materials are provided “as is” without warranty of any kind, either express or implied. Whet Cloud LLC, shall not be liable for any direct, special, indirect, incidental, consequential, or punitive damages, including without limitation, lost revenues or lost profits, which may result from the use of, access to, or inability to use these materials. Under no circumstances will the total liability of Whet Cloud LLC, to you exceed the price paid for use of the service.
        <br /><br />
        Corrections and Changes.
        <br />
        While we endeavor to keep these materials up to date, Whet Cloud LLC, cannot assume responsibility for any errors or omissions in these materials. Whet Cloud LLC, Inc. further does not warrant the accuracy or completeness of the information, text, graphics, links or other items contained within these materials. Whet Cloud LLC, may make changes to these materials, or to the products or services described herein, at any time without notice, and makes no commitment to update the information contained herein. Whet Cloud LLC, reserves the right to terminate your access to the Service in the event that you violate these Terms and Conditions, or for any reason whatever.
        <br /><br />
        Indemnification.
        <br />
        You agree to defend, indemnify, and hold harmless Whet Cloud LLC., its affiliates and subsidiaries, and all of their respective directors, officers, employees, representatives, proprietors, partners, shareholders, servants, principals, agents, predecessors, successors, assigns, and attorneys from and against any and all claims, proceedings, damages, injuries, liabilities, losses, costs, and expenses (including attorney’s fees and litigation expenses) relating to or arising from your use of the Service and any breach by you of these Terms and Conditions.
        <br /><br />
        Links to Other Web Services.
        <br />
        This Service may, from time to time, contain links to other Internet web services for the convenience of users in locating one of our clients’ services, information, or services that may be of interest. These services are maintained by organizations over which Whet Cloud LLC, exercises no control, and Whet Cloud LLC expressly disclaims any responsibility for the content, the accuracy of the information and/or quality of products or services provided by or advertised on these third-party services. Whet Cloud LLC, does not control, endorse, promote, or have any affiliation with any other web service unless expressly stated herein.
        <br /><br />
        Use of the Internet.
        <br />
        Use of the Internet is solely at your own risk and is subject to all applicable provincial, national and international laws, and regulations. Neither Whet Cloud LLC, nor its affiliates will be liable for any loss resulting from a cause over which they do not have direct control, including but not limited to failure of electronic or mechanical equipment or communication lines, telephone or other interconnection problems, computer viruses, unauthorized access, theft, operator errors, severe weather, earthquakes, natural disasters, strikes or other labor problems, wars, or governmental restrictions.
        <br /><br />
        Governing Law and Jurisdiction
        <br />
        These Terms and Conditions will be governed by and construed in accordance with the laws of the State of Texas, United States of America, without reference to its choice of law rules. By accessing, viewing, or using the material on the Service, you consent to the jurisdiction of the federal and state courts presiding in Frisco, Texas, and agree to accept service of process by mail and hereby waive any and all jurisdictional and venue defenses otherwise available. This Service is controlled and operated by Whet Cloud LLC, from its offices within the United States of America. Whet Cloud LLC, makes no representation that materials in the Service are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose to access this Service from other locations do so of their own volition and are responsible for compliance with applicable local laws.
        <br /><br />
        These Terms and Conditions constitute the entire agreement between you and Whet Cloud LLC, with respect to your use of the Service. You acknowledge that, in providing you access to and use of the Service, Whet Cloud LLC, has relied on your agreement to be legally bound by these Terms and Conditions
        <br /><br />
        Contact Information.
        <br />
        Whet Cloud LLC, welcomes your comments regarding this Terms of Use, please contact us by e-mail or postal mail.
        <br />
        Whet Cloud LLC,
        <br />
        13391 Torrington Drive
        <br />
        Frisco, Texas 75035
        <br />
        USA
        <br /><br />
      </p>
      <Footer />
    </div>
  )
}

export default terms
