import React from "react";
import "./overview.css";
import video from "../../Videos/company-overview.mp4";

function overview() {
  return (
    <div className="video-container">
      <h2 className="text-center mt-5">Overview Of Our Company</h2>
      <div className="video-wrapper my-5">
        <video width="700" height="125" controls className="video">
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default overview;
