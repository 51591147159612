import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./navbar.css";

function MyNavbar() {
  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary myNavbar">
        <Container>
          <Navbar.Brand to="">
            <img src="./Images/header-logo.png" className="logo" alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav">
              <Nav.Link as={NavLink} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/privacy-policy">
                Privacy Policy
              </Nav.Link>
              <Nav.Link as={NavLink} to="/terms">
                Terms of Services
              </Nav.Link>
              <a
                class="nav-link custom-contact-button text-center"
                href="#contactus"
                tabindex="-1"
                aria-disabled="true"
              >
                Contact Us
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default MyNavbar;
