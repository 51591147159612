import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Terms from './components/terms/terms';
import Privacy from './components/privacy/privacy';



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);


