import React from "react";
import Card from "react-bootstrap/Card";
import "./Features.css";

function Features() {
  return (
    <div>
      <h1 className="text-center my-5 features-heading">Services</h1>
      <div className="features row">
        <Card className="features-card">
          <Card.Img
            variant="top"
            src="./Images/2.png"
            className="features-img"
          />
          <Card.Body>
            <Card.Title className="features-title">
              Artificial Intelligence
            </Card.Title>
            <Card.Text className="features-desc">
              We excel in software solutions, leveraging Artificial Intelligence
              across various fields to deliver top-notch coding services.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="features-card">
          <Card.Img
            variant="top"
            src="./Images/3.png"
            className="features-img"
          />
          <Card.Body>
            <Card.Title className="features-title">Machine Learning</Card.Title>
            <Card.Text className="features-desc">
              Whetcloud offers advanced Python services, including collaboration
              middleware, predictive analytics, and cutting-edge technology
              solutions.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="features-card">
          <Card.Img
            variant="top"
            src="./Images/8.png"
            className="features-img"
          />
          <Card.Body>
            <Card.Title className="features-title">
              Digital Marketing
            </Card.Title>
            <Card.Text className="features-desc">
              Whetcloud excels in digital marketing, offering advanced solutions
              that span analytics, technology, and strategic services.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="features-card">
          <Card.Img
            variant="top"
            src="./Images/4.png"
            className="features-img"
          />
          <Card.Body>
            <Card.Title className="features-title">Web Development</Card.Title>
            <Card.Text className="features-desc">
              Our custom web development services are a sophisticated blend of
              intelligent programming, creative design & forward-thinking
              business acumen.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="features-card">
          <Card.Img
            variant="top"
            src="./Images/7.png"
            className="features-img"
          />
          <Card.Body>
            <Card.Title className="features-title">
              Mobile App Development
            </Card.Title>
            <Card.Text className="features-desc">
              From developing mobile apps to mobile solutions that empower your
              business we have the tech experts to get you going. Have an App
              Idea? contact us to get started.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="features-card">
          <Card.Img
            variant="top"
            src="./Images/5.png"
            className="features-img"
          />
          <Card.Body>
            <Card.Title className="features-title">
              Blockchain Services
            </Card.Title>
            <Card.Text className="features-desc">
              Whetcloud stands at the forefront of blockchain excellence,
              crafting a seamless and secured blend of cutting-edge technology.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Features;
