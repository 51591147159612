import "./header.css";

import MyNavbar from '../Navbar/navbar'

function BasicExample() {
  return (
    <div>
      <MyNavbar />
      <img className="background" alt="Header logo" src="./Images/header-image.jpg"></img>
    </div>
  );
}

export default BasicExample;
