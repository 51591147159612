import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header/header";
import Projects from "./components/project-section/projects";
import Features from "./components/features/Features";
import Layouts from "./components/Layouts/Layouts";
import FullyResponsive from "./components/fully-responsive/fully-responsive";
import Footer from "./components/footer/footer";
import ContactUsSection from "./components/contactus/contactus";
import Overview from "./components/overview/overview";
import Company from './components/companyOverview/company-overview';

function App() {
  return (
    <div>
      <Header />
      <br />
      <Projects />
      <br />
      <Company />
      <br />
      <Features />
      <br />
      <br />
      <br />
      <FullyResponsive />
      <Layouts />
      <Overview />
      <br />
      <ContactUsSection />
      <Footer />
    </div>
  );
}

export default App;
