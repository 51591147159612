import "./footer.css";

function Footer() {
  return (
    <div className="section-footer">
      <section></section>
      <footer className="footer-bs">
        <div className="row">
          <div className="col-md-3 footer-brand animated fadeInLeft">
            <img
              className="logo-footer"
              src="./Images/header-logo.png"
              alt="Footer logo"
            ></img>
          </div>
          <div className="col-md-3 footer-nav animated fadeInUp">
            <h4 className="footer-heading">Services</h4>
            <div className="col-md-6">
              <ul className="pages">
                <li>
                  <a href="/terms">Terms of Use</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 footer-social animated fadeInDown">
            <h4 className="footer-heading">Follow Us</h4>
            <ul>
              <li>
                <a href="https://www.linkedin.com/company/whetcloud/">
                  Linkedin
                </a>
              </li>

              <li>
                <a href="mailto:info@whetcloud.com">Email Us</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 footer-nav animated fadeInUp">
            <h4 className="footer-heading">CONTACT</h4>
            <div className="col-md-6">
              <ul className="pages">
                <li>Email To: info@whetcloud.com</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div>Copyright © WhetCloud 2016 - 2024 All Rights Reserved</div>
      </div>
    </div>
  );
}

export default Footer;
