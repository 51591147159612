import React from "react";
import Card from "react-bootstrap/Card";
import "./Layouts.css";

function Layouts() {
  return (
    <div>
      <h1 className="text-center my-5 layout-heading">Projects</h1>
      <div className="layouts row">
        <Card className="layout-card">
          <img
            src="./Images/page-layout-11.jpeg"
            alt=""
            className="layout-img-1"
          />
          <p className="project-title">d-vour's resturant mobile app</p>
        </Card>
        <Card className="layout-card">
          <img
            src="./Images/page-layout-21.jpeg"
            alt=""
            className="layout-img-1"
          />
          <p className="project-title">d-vour's user mobile app</p>
        </Card>
        <Card className="layout-card">
          <img
            src="./Images/page-layout-41.jpeg"
            alt=""
            className="layout-img-1"
          />
          <p className="project-title">d-vour's resturant dashboard</p>
        </Card>
        <Card className="layout-card">
          <img
            src="./Images/page-layout-31.jpeg"
            alt=""
            className="layout-img-1"
          />
          <p className="project-title">Admin Console</p>
        </Card>
        <Card className="layout-card">
          <img
            src="./Images/page-layout-51.jpeg"
            alt=""
            className="layout-img-1"
          />
          <p className="project-title">d-vour's website</p>
        </Card>
        <Card className="layout-card">
          <img
            src="./Images/page-layout-61.jpeg"
            alt=""
            className="layout-img-1"
          />
          <p className="project-title">Forunpoint website</p>
        </Card>
      </div>
    </div>
  );
}

export default Layouts;
