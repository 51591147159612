import "./fully-responsive.css";

const FullyResponsive = () => {
  return (
    <div class="responsive-container">
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <div class="delivery-card">
            <div class="card-body">
              <h1 className="responsive-heading" >Innovative Mobile App Development Services</h1>
              <p className="responsive-pera">Welcome to WhetCloud, where innovation meets functionality! We specialize in delivering cutting-edge mobile app development services tailored to meet the diverse needs of businesses and individuals. With our team of skilled developers and a commitment to excellence, we transform your ideas into powerful, user-friendly, and visually stunning mobile applications.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="delivery-card">
            <div class="card-body">
              <img
                className="delivery-section-img"
                src="./Images/responsive-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullyResponsive;
